import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import Best10RealEstate from '@components/global/gallery/Best10RealEstate'
import Best10Features from '@components/global/product/Best10Features'
import ReviewsTrustedByTheBest from '@components/global/Reviews/TrustedByTheBest'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  pageData as page,
  bannerData,
  listData,
  featuresData,
} from '@src/data/Best10TrueCustom'
import useBest10TrueCustomMetadata from '@hooks/best-10-true-custom-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestTrueCustom.module.scss'

const Best10TrueCustomWebsites = () => {
  const {
    bannerBGDesktop,
    bannerBGTablet,
    bannerBGPhone,
    bannerImgDesktop,
    bannerImg,
    listMarkLaptop,
    listMarkTablet,
    listMarkPhone,
    listMichelleLaptop,
    listMichelleTablet,
    listMichellePhone,
    listKilianLaptop,
    listKilianTablet,
    listKilianPhone,
    listAngelLaptop,
    listAngelTablet,
    listAngelPhone,
    listFerrettiLaptop,
    listFerrettiTablet,
    listFerrettiPhone,
    listShannaLaptop,
    listShannaTablet,
    listShannaPhone,
    listCityLaptop,
    listCityTablet,
    listCityPhone,
    listDngLaptop,
    listDngTablet,
    listDngPhone,
    listHotLaptop,
    listHotTablet,
    listHotPhone,
    listMatLaptop,
    listMatTablet,
    listMatPhone,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    featuresImg,
    featuresAccent,
  } = useBest10TrueCustomMetadata()

  const imgListData = [
    {
      laptop: listMarkLaptop,
      tablet: listMarkTablet,
      phone: listMarkPhone,
      identifier: logo1,
    },
    {
      laptop: listMichelleLaptop,
      tablet: listMichelleTablet,
      phone: listMichellePhone,
      identifier: logo2,
    },
    {
      laptop: listKilianLaptop,
      tablet: listKilianTablet,
      phone: listKilianPhone,
      identifier: logo3,
    },
    {
      laptop: listAngelLaptop,
      tablet: listAngelTablet,
      phone: listAngelPhone,
      identifier: logo4,
    },
    {
      laptop: listFerrettiLaptop,
      tablet: listFerrettiTablet,
      phone: listFerrettiPhone,
      identifier: logo5,
    },
    {
      laptop: listShannaLaptop,
      tablet: listShannaTablet,
      phone: listShannaPhone,
      identifier: logo6,
    },
    {
      laptop: listCityLaptop,
      tablet: listCityTablet,
      phone: listCityPhone,
      identifier: logo7,
    },
    {
      laptop: listDngLaptop,
      tablet: listDngTablet,
      phone: listDngPhone,
      identifier: logo8,
    },
    {
      laptop: listHotLaptop,
      tablet: listHotTablet,
      phone: listHotPhone,
      identifier: logo9,
    },
    {
      laptop: listMatLaptop,
      tablet: listMatTablet,
      phone: listMatPhone,
      identifier: logo10,
    },
  ]
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage
      hasWhiteTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        breadcrumbs={page.breadcrumbs}
        wrapperClassName={cx.banner}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
        bg={extractImage(
          isDesktop ? bannerBGDesktop : isPhone ? bannerBGPhone : bannerBGTablet
        )}
        img={extractImage(isDesktop ? bannerImgDesktop : bannerImg)}
      />
      <Best10RealEstate
        hasIdentifier
        hasScrollLaptop
        wrapperClassName={cx.list}
        itemLayout={listData.list.itemLayout}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        imgItems={imgListData}
        identifierLayout={listData.list.identifierLayout}
        identifierLink={listData.list.identifierLink}
        scrollLaptopBackcover={listData.list.scrollLaptopBackcover}
      />
      <Best10Features
        hasModalButton
        wrapperClassName={cx.features}
        heading={featuresData.heading.text}
        subheading={featuresData.subheading.text}
        description={featuresData.description.text}
        img={extractImage(featuresImg)}
        accent={extractImage(featuresAccent)}
        imgAlt={featuresData.imgAlt}
        bestItems={featuresData.best}
        highlightsItems={featuresData.highlights}
        buttonText={featuresData.buttonText}
        buttonLink={featuresData.buttonLink}
      />
      <ReviewsTrustedByTheBest
        wrapperClassName={cx.logos}
        alt={true}
        title={page?.thankYou?.brands?.title}
        subtitle={page?.thankYou?.brands?.subtitle}
        brands={page?.thankYou?.brands?.items}
      />
      <ReviewsServiceSetsUsApart
        wrapperClassName={cx.reviews}
        title={page?.thankYou?.reviews?.title}
        subtitle={page?.thankYou?.reviews?.subtitle}
        backgroundUrl={page?.thankYou?.reviews?.review_background?.url}
        reviewItems={page?.thankYou?.reviews?.items}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10TrueCustomWebsites
